import React from "react"
import GrowthRanker from '../../images/growth-ranker.png'
import GoodAudience from '../../images/good_audience.png'
import Quora from '../../images/quora-black.png'
import Gizmodo from '../../images/gizmodo.png'
import Addon from '../../images/addon.png'
import WikiHow from '../../images/wikihow.png'

const Featured = _ => {
    return <section className={'featured'} key={'featured'}>
        <div className={'content'}>
            <strong>Featured On</strong>
            <ul>
                <li><img style={{marginBottom: -5}} src={Gizmodo} alt='gizmodo.com'/></li>
                <li><img src={GrowthRanker} alt={'Growth Ranker'}/></li>
                <li><img src={WikiHow} alt={'WikiHow'}/></li>
                <li><img src={Quora} alt={'Quora'}/></li>
                <li><img src={GoodAudience} alt={'Good Audience'}/></li>
                <li><img src={Addon} alt={'Addon'}/></li>
            </ul>
        </div>
    </section>
};

export default Featured
