import React from "react"
import StarFilled from '../../images/star.svg'
import StarEmpty from '../../images/star-outline.svg'
import StarHalf from '../../images/star-half.svg'

class Hero extends React.Component {

    componentDidMount = _ => {
        window === "undefined" || window.setTimeout(_ => {
            !window.FB || window.FB.XFBML.parse();
        }, 100)
    }

    render() {
        const {actionButton, browserLinks} = this.props;

        return <section className={'hero'} key={'hero'}>
            <div className={'content'}>
                <div className={'title'}>Download All Images</div>
                <div className={'text'}>
                    <h1>The Smart way to Save All Images from any Web Page.</h1>
                    <div className={'p'}>
                        Average person needs 300 clicks to save 100 images.<br/>
                        <u>You only need 1.</u>
                    </div>
                    <div className={'action-wrapper'}>
                        <div className={'hover animated flash'}/>
                        {actionButton()}
                    </div>
                    {browserLinks}
                </div>
                <div className={'rating'}>
                    <div>3.8/5 based on 249 reviews</div>
                    <div className={'stars'}>
                        <img src={StarFilled} alt={''}/>
                        <img src={StarFilled} alt={''}/>
                        <img src={StarFilled} alt={''}/>
                        <img src={StarHalf} alt={''}/>
                        <img src={StarEmpty} alt={''}/>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default Hero;
