import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLoad from "react-lazyload"

import Hero from "../components/index/hero"
import Featured from "../components/index/featured"
import Uses from "../components/index/uses"
import How from "../components/index/how"
import Formats from "../components/index/formats"
import Testimonials from "../components/index/testimonials"
import Comparison from "../components/index/comparison"
import GetStarted from "../components/index/started"
import Questions from "../components/index/questions"
import Chrome from "../images/chrome.svg"
import Firefox from "../images/firefox.svg"
import Edge from "../images/microsoft-edge.svg"
import Brave from "../images/brave.svg"

import "../components/index/landing-page.css"

const firefoxURL = "https://addons.mozilla.org/en-US/firefox/addon/download-all-images/"
const edgeURL = "https://microsoftedge.microsoft.com/addons/detail/hpceppbbhmfebdnpaeiififakbogkgfa"
const chromeURL = "https://chrome.google.com/webstore/detail/ifipmflagepipjokmbdecpmjbibjnakm"
// TODO: this doesn't work, gatsby is so stupid....
const isFirefox = () => false //typeof window !== 'undefined' ? window.navigator.userAgent.indexOf("Firefox") > -1 : false
const isEdge = () => false //typeof window !== 'undefined' ? window.navigator.userAgent.indexOf("Edg/") > -1 : false

const installUri = () => {
  if (isFirefox()) return firefoxURL
  if (isEdge()) return edgeURL
  return chromeURL
}
const actionButton = _ => <a href={installUri()} className={"btn cta"}>Install For Free</a>

const browserLinks = <div className={"browser-links"}>
  <strong>Install for browser:</strong> &nbsp; &nbsp;
  <a href={chromeURL} title={"Chrome"}><img alt={"chrome"} src={Chrome}/></a>
  <a href={chromeURL} title={"Brave"}><img alt={"brave"} src={Brave}/></a>
  <a href={firefoxURL} title={"Firefox"}><img alt={"firefox"} src={Firefox}/></a>
  <a href={edgeURL} title={"Edge"}><img alt={"edge"} src={Edge}/></a>
</div>

const height = typeof window !== "undefined" ? window.innerHeight : 800

const IndexPage = () => (
  <Layout transparentHeader={true} isHome={true}>
    <SEO title="Home" keywords={[`download all images`, `chrome extension`, `firefox addon`, `edge extension`]}/>
    <Hero actionButton={actionButton} browserLinks={browserLinks}/>
    <LazyLoad height={height} once offset={400}>
      {Featured()}
      {Uses()}
    </LazyLoad>
    <LazyLoad height={height * 4} once offset={400}>
      {How()}
      {Formats()}
      {Testimonials()}
      {Comparison()}
      {GetStarted(actionButton, browserLinks)}
      {Questions()}
    </LazyLoad>
  </Layout>
)

export default IndexPage
