import React from "react"

const GetStarted = (actionButton, browserLinks) => {
    return <section className={'start-now'} key={'sstart-now'}>
        <div className={'content'}>
            <h2>Ready to Get Started?</h2>
            <p style={{maxWidth:500}}>Join more than <strong style={{letterSpacing:1}}>140,000</strong> other happy users
                <wbr/> from around the world!</p>
            {actionButton()}
            {browserLinks}
        </div>
    </section>;
};


export default GetStarted
