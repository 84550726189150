import React from "react"
import {Carousel} from 'react-responsive-carousel';
import AppIcon from '../../images/128x128.png'
import WifiStreghtLow from '../../images/wifi-strength-1-alert.svg'
import WifiStreghtHigh from '../../images/wifi-strength-4.svg'
import Snowflake from '../../images/snowflake.svg'
import Fire from '../../images/fire.svg'
import Buggy from '../../images/bug.svg'
import OS from '../../images/desktop-mac.svg'
import DoorOpen from '../../images/door-open.svg'
import DoorClosed from '../../images/door-closed.svg'
import AccountGroup from '../../images/account-group.svg'
import AccountPrivate from '../../images/account-key.svg'
import Slow from '../../images/calendar-clock.svg'
import Fast from '../../images/clock-fast.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css";


const cardRenderer = (name, txt1, txt2, img1, img2, idx) => <div className={'card'} key={idx}>
    <div>
        <span>{name}</span> <span dangerouslySetInnerHTML={{__html: txt1}}/>
        <img src={img1} alt={''} className={'related-img'}/>
        {/*{redFail()}*/}
    </div>
    <div>
        <span>Download All Images</span><span dangerouslySetInnerHTML={{__html: txt2}}/>
        <img src={img2} alt={''} className={'related-img' +
        (img2 === Fire ? " animated pulse infinite" : "")}/>
        {/*{greenCheck()}*/}
        <i>vs</i>
    </div>
</div>

const text = [
    ['Image Cyborg',
        'Downloads images on a server. This may cause bottlenecks in service availability.',
        'Downloads images right on your device. No servers, no bottlenecks in availability.',
        WifiStreghtLow, WifiStreghtHigh],
    ['Image Downloader',
        'Downloads images individually. This will freeze your browser if you try to download many images',
        'Packs all images into a zip file. There will be exactly 1 download operation/batch.',
        Snowflake, Fire],
    ['Image Cyborg',
        'Server cannot access private web pages that require login.',
        'You can download images from any page including those that require login.',
        DoorClosed, DoorOpen],
    ['Image Downloader',
        'Custom user interface which may be buggy and has limited features.',
        'Use your trusty operating system - with features like search, sort and image meta data - to work with images.',
        Buggy, OS],
    ['Image Cyborg',
        'Images you download are visible to Image Cyborg owner.',
        'Images you download are visible to you and <u>you only</u>.',
        AccountGroup, AccountPrivate],
    ['Image Downloader', 'You have to choose individual images -- you might as well save images using the browser.',
        'Extension will grab everything with one click. You can choose what you want later.',
        Slow, Fast]
];

const Comparison = _ => {
    return <section className={'alternatives'} key={'alts'}>
        <div className={'image'}>
            <img src={AppIcon} alt={'Download All Images'}/>
        </div>
        <div className={'content'}>
            <h2>Better Than Alternatives</h2>
            <p>There are a few other solutions out there. Here is how
                Download All Images compares against those solutions.</p>

            <div className={'compare-slider'}>
                <Carousel centerMode={false}
                          interval={10000}
                          showStatus={false}
                          infiniteLoop={true}
                          showArrows={false}
                          dynamicHeight={false}
                          autoPlay={true}
                          showThumbs={false}>
                    {text.map((obj, i) => cardRenderer(obj[0], obj[1], obj[2], obj[3], obj[4], i))}
                </Carousel>
            </div>
        </div>
    </section>
};

export default Comparison
