import React from "react"
import StarFilled from '../../images/star.svg'

const FiveStars = () => {
    return <div>
        <img src={StarFilled} alt={''}/>
        <img src={StarFilled} alt={''}/>
        <img src={StarFilled} alt={''}/>
        <img src={StarFilled} alt={''}/>
        <img src={StarFilled} alt={''}/>
    </div>
};

const Testimonials = _ => {
    return <section className={'testimonials'} key={'testimonials'}>
        <div className={'content'}>
            <div className={'testimonial'}>
                <blockquote>This extension saved me HOURS of time! Thank you so much!</blockquote>
                <div><span>— Beth Williams</span> {FiveStars()}</div>
            </div>
        </div>
    </section>
};

export default Testimonials
