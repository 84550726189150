import React from "react"
import OfflineIcon from '../../images/wifi-strength-alert-outline.svg'
import TransferIcon from '../../images/transfer-up.svg'
import PhotosIcon from '../../images/image-filter.svg'
import ResearchIcon from '../../images/flask-outline.svg'

const Uses = _ => {
    return <section className={'usage'} key={'uses'}>
        <div className={'content'}>
            <h2>You can use it for...</h2>
            <p>Download All Images is helpful in a number of situations,
                but here are some common usage examples</p>
            <div className={'usage-grid'}>
                <div>
                    <img src={TransferIcon} alt={''}/>
                    <div>
                        <h3>Backup & Transfer</h3>
                        <p>Make backups of images to protect them against data loss or to move them to another
                            storage.</p>
                    </div>
                </div>
                <div>
                    <img src={ResearchIcon} alt={''}/>
                    <div>
                        <h3>Study & Research</h3>
                        <p>Generate image-based data sets for machine learning or other types of research.</p>
                    </div>
                </div>
                <div>
                    <img src={OfflineIcon} alt={''}/>
                    <div>
                        <h3>Offline Accessibility</h3>
                        <p>Store images that normally require network connection to make them available when you are
                            offline.</p>
                    </div>
                </div>
                <div>
                    <img src={PhotosIcon} alt={''}/>
                    <div>
                        <h3>Image Collection</h3>
                        <p>Whether it's about a work project or your favorite celebrity, you can
                            easily create a collection of images for personal use.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default Uses
