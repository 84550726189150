import React from "react"
import FormatsImage from '../../images/formats.png'
import FormatsImageWide from '../../images/formatswide.png'

const Formats = _ => {
    return <section className={'formats'} key={'formats'}>
        <div className={'content'}>
            <h2>Works With Many Image Formats</h2>
            <p>Whether you are saving scenic photos, hottest memes, or animated GIFs,
                Download All Images has got you covered.</p>
            <img className={'compact'} src={FormatsImage} alt={''}/>
            <img className={'wide'} src={FormatsImageWide} alt={''}/>
            <ul className={'grid'}>
                <li><h3>jpg / jpeg</h3>
                    <p>Common lossy compression format</p>
                </li>
                <li><h3>png</h3>
                    <p>Common lossless compression format</p>
                </li>
                <li><h3>gif</h3>
                    <p>With soft or hard "g", you choose.</p>
                </li>
                <li><h3>webp</h3>
                    <p>Fast, modern image format</p>
                </li>
                <li><h3>svg</h3>
                    <p>Inline or remote vector images</p>
                </li>
                <li><h3>bmp</h3>
                    <p>Uncompressed raster images</p>
                </li>
                <li><h3>Base64</h3>
                    <p>Binary encoded images</p></li>
                <li><h3>tiff/tif</h3>
                    <p>Carryover from the past times</p>
                </li>
            </ul>
        </div>
    </section>
};

export default Formats
