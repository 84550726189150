import React from "react"
import ClickIcon from '../../images/click_icon.png'
import FindPhotos from '../../images/photos.png'
import Unpack from '../../images/unpack.png'

const HowItWorks = _ => {
    return <section className={'features'} key={'how'}>
        <div className={'content'}>
            <h2>How to use it</h2>
            <p>This Chrome extension downloads all images from a web page and packs them into a zip file.
                Easily download hundreds of images from Google Images&trade;, Instagram, Bing image search, etc.</p>
            <div className={'step-wrapper'}>
                <img className="feat-image" src={ClickIcon} alt={''}/>
                <div className={'step one'}>
                    <h3 className={'number'}>1</h3>
                    <h3>Start Download by Clicking Extension Icon</h3>
                    <p>Navigate to the page where you want to save images then click the extension icon.
                        Extension icon is located in the top right corner of browser, next to the address bar.</p>
                </div>
            </div>
            <div className={'step-wrapper'}>
                <img className="feat-image" src={FindPhotos} alt={''}/>
                <div className={'step two'}>
                    <h3 className={'number'}>2</h3>
                    <h3>Extension Will Find & Download Images</h3>
                    <p>Download All Images extension will analyze the current browser page to identify images. It will
                        then
                        download them
                        and
                        package them into a single zip file.</p>
                </div>
            </div>
            <div className={'step-wrapper'}>
                <img className="feat-image" src={Unpack} alt={''}/>
                <div className={'step three'}>
                    <h3 className={'number'}>3</h3>
                    <h3>Unzip Images</h3>
                    <p>After receiving the images, unzip the file and do whatever you want with the images.</p>
                </div>
            </div>
        </div>
    </section>
};

export default HowItWorks
