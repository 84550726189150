import React from "react"
import {Link} from 'gatsby'

const Questions = _ => {
    return <section className={'questions'} key={'questions'}>
        <div className={'content'}>
            <h2>FAQs</h2>
            <div className={'faqs-collection'}>
                {[['Why does this extension require permission to read and change data on all sites?',
                    'This extension requires read access to download images which are hosted across various different domains. Without this permissions all attempts to download images would fail. Contrary to what the description says, this extension does not change any data. However we cannot change the description because it is set by Google.'],
                    ['I see this browser extension is available for Chrome but I prefer *some other browser*. How do you choose which browsers you support?',
                        'As of May 2021, Download All Images is cross-browser! It is currently available for Chrome, Firefox, Edge, and Brave. Release for Opera is pending and support for Safari is planned.']].map((obj,i) =>
                    <div className={'q'} key={i}>
                        <h3>{obj[0]}</h3>
                        <p>{obj[1]}</p>
                    </div>)}
            </div>
            <div className={'actions'}>
                <span>Got more questions?</span> &nbsp; <Link to={'/faqs'}>See All FAQs</Link>
            </div>
        </div>
    </section>;
};

export default Questions
